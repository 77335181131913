import { Button } from 'components/common';

import { usePerformance } from 'modules/performance/usePerformance';

type DeleteCustomViewModalProps = {
  onClose: () => void;
  viewId: string | null;
};

export const DeleteCustomViewModal = ({ onClose, viewId }: DeleteCustomViewModalProps) => {
  const { deleteView } = usePerformance();

  const handleDeleteAction = () => {
    viewId && deleteView(viewId);
    onClose();
  };

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-10 text-secondary-black">
            Delete custom view
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          Are you sure you want to permanently delete this view?
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={onClose} className="w-20">
            Cancel
          </Button>
          <Button size="md" variant="danger" onClick={handleDeleteAction}>
            Delete
          </Button>
        </div>
      </div>
    </div>
  );
};
