import { Menu as MenuAntd, MenuItemProps, MenuProps, SubMenuProps } from 'antd';
import classNames from 'classnames';
import { memo } from 'react';

import { ctvTwMerge } from 'services/twMerge/ctvTwMerge';

export const Menu = memo(({ children, className, ...rest }: MenuProps) => (
  <MenuAntd className={ctvTwMerge(classNames('ctv-menu'), className)} {...rest}>
    {children}
  </MenuAntd>
));

Menu.displayName = 'Menu';

export const SubMenu = memo(({ children, className, ...rest }: SubMenuProps) => (
  <MenuAntd.SubMenu className={classNames(className)} {...rest}>
    {children}
  </MenuAntd.SubMenu>
));

SubMenu.displayName = 'SubMenu';

export const MenuItem = memo(({ children, className, ...rest }: MenuItemProps) => (
  <MenuAntd.Item className={classNames(className)} {...rest}>
    {children}
  </MenuAntd.Item>
));

MenuItem.displayName = 'MenuItem';
