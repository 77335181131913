import { useEffect, useMemo, useState } from 'react';
import { arraysEqual } from 'utils';

import { Button, Checkbox } from 'components/common';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import { usePerformance } from 'modules/performance/usePerformance';

type EditWidgetModalProps = {
  onClose: () => void;
};

const MAX_METRICS_LENGTH: number = 6;

export const EditWidgetsModal = ({ onClose }: EditWidgetModalProps) => {
  const [selectedMetrics, setSelectedMetrics] = useState<string[]>([]);
  const [initialMetrics, setInitialMetrics] = useState<string[]>([]);

  const { getWidgets, displayWidgets, setCustomView, setViewId } = usePerformance();
  const { data: constants } = useConstantsQuery(null);

  const handleEditWidgets = () => {
    setCustomView(selectedMetrics);
    setViewId('0');
    onClose();
  };

  useEffect(() => {
    !displayWidgets && getWidgets();
  }, [getWidgets, displayWidgets]);

  useEffect(() => {
    if (displayWidgets) {
      const selectedDefault = displayWidgets.filter((widget) => widget).map((widget) => widget.key);

      setInitialMetrics(selectedDefault);
      setSelectedMetrics(selectedDefault);
    }
  }, [displayWidgets]);

  const getWidgetMetrics = useMemo(
    () =>
      constants?.Metrics
        ? Object.entries(constants?.Metrics).map(([key, value]) => ({
            key,
            value: value.shownName,
          }))
        : [],
    [constants?.Metrics],
  );

  const handleMetricChange = (metric: string, isChecked: boolean) => {
    setSelectedMetrics((prev) => {
      if (isChecked) {
        return prev.length < MAX_METRICS_LENGTH ? [...prev, metric] : prev;
      } else {
        return prev.filter((m) => m !== metric);
      }
    });
  };

  const isDisabled = (metric: string): boolean => {
    return !selectedMetrics?.includes(metric) && selectedMetrics.length === MAX_METRICS_LENGTH;
  };

  const isSaveDisabled =
    selectedMetrics.length === 0 || arraysEqual(initialMetrics, selectedMetrics);

  return (
    <div className="w-[684px] bg-white rounded-xl shadow flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex border-b border-primary-gray-100">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-10 text-secondary-black">
            Edit widgets
          </h1>
        </div>
      </div>
      <div className="max-h-[753px] flex flex-row gap-6 stretch-to-form overflow-y-auto">
        <div className="bg-primary-gray-50 pl-6 py-4 flex-1 border-r border-primary-gray-100">
          <div className="self-stretch pb-2 font-medium leading-snug">Library</div>
          {getWidgetMetrics.map(({ key, value }, index) => (
            <Checkbox
              key={index}
              className="pb-3"
              disabled={isDisabled(key)}
              checked={selectedMetrics?.includes(key)}
              onChange={(e) => handleMetricChange(key, e.target.checked)}
            >
              <span className="text-base-sm font-normal leading-tight">{value}</span>
            </Checkbox>
          ))}
        </div>
        <div className="pr-6 py-4 flex-1" />
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={onClose} className="w-20">
            Cancel
          </Button>
          <Button size="md" variant="primary" disabled={isSaveDisabled} onClick={handleEditWidgets}>
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
