import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import { ChevronLeft, Check, X } from 'feather-icons-react';
import { useCallback, useEffect, useRef } from 'react';
import { useForm } from 'react-hook-form';
import { expandConstants, omitProps } from 'utils';

import { GlobalFilter } from 'components/GlobalFilter';
import {
  Button,
  Checkbox,
  CheckboxGroup,
  DateRangePicker,
  Field,
  IconButton,
  Indicator,
  Input,
  RadioButton,
  RadioGroup,
} from 'components/common';
import { useDefaultDateRangePresets } from 'components/common/dateRangePicker/dateRangePresets';
import { EmailInput } from 'components/common/emailInput';
import { Select, Option } from 'components/common/select';
import SortableList, { DragHandle } from 'components/common/sortableList';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import NotificationService from 'modules/notifications/notificationService';
import { useOrganization } from 'modules/organization/useOrganization';
import { Report, ReportStatusResponse } from 'modules/report/reportApiTypes';
import { useReport } from 'modules/report/useReport';

import {
  HOURS,
  schema,
  FormType,
  CreateReportProps,
  toEnum,
  OVERALL,
  NAME_MAX_LENGTH,
  CUSTOM,
  STATUS_TYPES,
  DEFAULT_DAILY_CADENCE,
  DEFAULT_TIME_SLICE,
} from './ReportsTypes';
import { usePolling } from './usePolling';

const dateFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';

export const CreateReport = ({
  setIsCreateFormOpen,
  selectedReport,
  refetch,
}: CreateReportProps) => {
  const { currentAdvertiserId } = useOrganization();
  const { saveReport, isLoading, downloadReport, getReportStatus } = useReport();
  const { data: constants } = useConstantsQuery(null);
  const defaultDateRangePresets = useDefaultDateRangePresets();
  const newReportRef = useRef<Report | null>(null);

  const reportType = expandConstants((constants && constants.ReportType) || {});
  const attributionSettings = (constants && constants.AttributionSettings) || {};
  const methodologyOptions = expandConstants((constants && constants.MethodologyOptions) || {});
  const conversionWindow = toEnum((constants && constants.ConversionWindow) || []);
  const intervals = expandConstants((constants && constants.Intervals) || {});
  const dimensions = expandConstants((constants && constants.Dimensions) || {});
  const metrics = expandConstants((constants && constants.Metrics) || {});
  const formats = expandConstants((constants && constants.ReportFormat) || {});
  const cadence = expandConstants((constants && omitProps(constants.Cadence, ['YEARLY'])) || {});
  const weeklyCadence = expandConstants((constants && constants.CadenceWeekly) || {});
  const monthlyCadence = expandConstants((constants && constants.CadenceMonthly) || {});
  const dailyCadence = expandConstants(HOURS);
  const timeSlices = (constants && constants.TimeSlice) || {};

  const { startDate, endDate, cadenceType, cadenceValue, timeSlice } = selectedReport || {};

  const existingDateRange =
    startDate && endDate
      ? { startDate: dayjs(startDate), endDate: dayjs(endDate), period: timeSlice }
      : null;

  const defaultTimeSlice = timeSlices[DEFAULT_TIME_SLICE];
  const defaultPreset = defaultDateRangePresets.find((p) => p.label === defaultTimeSlice);
  const defaultDateRange = {
    startDate: defaultPreset?.value?.[0],
    endDate: defaultPreset?.value?.[1],
    period: DEFAULT_TIME_SLICE,
  };

  const getCadenceValue = (type: { value: string }) =>
    cadenceType === type.value ? cadenceValue : null;

  const getExistingDimensions = (dimensions: string[]) =>
    dimensions?.length ? dimensions : [OVERALL];

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<FormType>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    values: {
      reportType: selectedReport?.reportType || reportType.PERFORMANCE.value,
      name: selectedReport?.name || '',
      dateRange: existingDateRange || defaultDateRange,
      showUniques: selectedReport?.showUniques || attributionSettings.TOTAL.value,
      methodology: selectedReport?.methodology || methodologyOptions.LAST_TOUCH.value,
      conversionWindow: conversionWindow[selectedReport?.conversionWindow || 30],
      interval: selectedReport?.interval || intervals.CUMULATIVE.value,
      dimensions: selectedReport ? getExistingDimensions(selectedReport?.dimensions) : [],
      dimensionFilters: selectedReport?.dimensionFilters || [],
      metrics: selectedReport?.metrics || [],
      reportFormat: selectedReport?.reportFormat || formats.CSV.value,
      isScheduled: selectedReport?.isScheduled || false,
      emails: selectedReport?.emails || [],
      cadenceType: cadenceType || cadence.DAILY.value,
      dailyCadence:
        selectedReport?.cadenceHour?.toString() || dailyCadence[DEFAULT_DAILY_CADENCE].value,
      weeklyCadence: getCadenceValue(cadence.WEEKLY) || weeklyCadence.SUNDAY.value,
      monthlyCadence: getCadenceValue(cadence.MONTHLY) || monthlyCadence.FIRST_OF_MONTH.value,
    },
  });
  const headerText = selectedReport ? 'Edit report' : 'Create a report';
  const isScheduleEnabled = watch('isScheduled');
  const isCustomDateRange = watch('dateRange')?.period === CUSTOM;

  useEffect(() => {
    if (isCustomDateRange) setValue('isScheduled', false);
  }, [isCustomDateRange, setValue]);

  const setEmails = useCallback(
    (value: string[]) => setValue('emails', value, { shouldValidate: true }),
    [setValue],
  );

  const hasOverall = (arr: string[]) => arr.some((i) => i === OVERALL);
  const skipIfOverall = (arr: string[]) => (hasOverall(arr) ? [] : arr);

  const handleFormSubmit = handleSubmit(async (data) => {
    const { startDate, endDate, period: timeSlice } = data.dateRange;

    const dimensions = skipIfOverall(data.dimensions);
    const basePayload = omitProps(data, [
      'dateRange',
      'weeklyCadence',
      'monthlyCadence',
      'dimensions',
      'dailyCadence',
    ]);

    const weeklyCadence =
      data.cadenceType === cadence.WEEKLY.value ? { weeklyCadence: data.weeklyCadence } : {};
    const monthlyCadence =
      data.cadenceType === cadence.MONTHLY.value ? { monthlyCadence: data.monthlyCadence } : {};
    const reportId = selectedReport ? { reportId: selectedReport.id } : {};

    const payload = {
      ...basePayload,
      advertiserId: currentAdvertiserId,
      startDate: startDate ? startDate?.format(dateFormat) : '',
      endDate: endDate ? endDate?.format(dateFormat) : '',
      timeSlice,
      dimensions,
      ...weeklyCadence,
      ...monthlyCadence,
      dailyCadence: parseInt(data.dailyCadence),
      ...reportId,
    };

    newReportRef.current = await saveReport(payload);
    refetch();
  });

  const handleSave = async (e: React.FormEvent<HTMLFormElement>) => {
    e?.preventDefault();

    await handleFormSubmit();

    if (newReportRef?.current?.id) setIsCreateFormOpen(false);
  };

  const stopCondition = ({ status }: ReportStatusResponse) =>
    status === STATUS_TYPES.COMPLETED || status === STATUS_TYPES.EMPTY;

  const { isPolling, startPolling, stopPolling } = usePolling(5000, stopCondition, 6);

  const handleStatusResponse = async (
    reportStatus: ReportStatusResponse | undefined,
    onCompleted: () => Promise<void>,
  ) => {
    if (reportStatus) {
      const { status } = reportStatus;

      if (status === STATUS_TYPES.COMPLETED) {
        await onCompleted();
        setIsCreateFormOpen(false);
      }
      if (status === STATUS_TYPES.EMPTY) {
        NotificationService.warn(
          'We’re unable to generate the report at this time as there’s no data available.',
        );
        setIsCreateFormOpen(false);
      }
      if (status === STATUS_TYPES.IN_PROGRESS) {
        NotificationService.warn(
          'Report generation is taking a bit longer than expected. You can download it later from the Reports table.',
        );
      }
    }
  };

  const handleDownloadClick = async () => {
    try {
      await handleFormSubmit();

      const { id, name } = newReportRef.current as Report;
      const reportStatus = await startPolling(() => getReportStatus(id));
      const onCompleted = () => downloadReport(id, name);

      handleStatusResponse(reportStatus, onCompleted);
    } catch (err) {
      NotificationService.error('An error occured while downloading a report');
    } finally {
      newReportRef.current = null;
    }
  };

  const handleBack = () => {
    stopPolling();
    setIsCreateFormOpen(false);
  };

  return (
    <div className="w-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex ctv-create-report">
      <div className="h-20 w-full border-b border-primary-gray-100 flex-col justify-start items-start inline-flex">
        <div className="w-256 px-8 py-5 justify-between items-start inline-flex">
          <div className="justify-start items-center gap-1 flex">
            <IconButton Icon={ChevronLeft} variant="tertiary" onClick={handleBack} />
            <div className="text-secondary-black text-heading-2xl font-bold leading-loose">
              {headerText}
            </div>
          </div>
          <div className="justify-start items-center gap-2 flex">
            <Button
              variant="outline"
              htmlType="submit"
              form="reportForm"
              isLoading={isLoading || isPolling}
            >
              Save
            </Button>
            <Button
              variant="primary"
              disabled={isScheduleEnabled}
              onClick={handleDownloadClick}
              isLoading={isLoading || isPolling}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
      <form
        id="reportForm"
        onSubmit={handleSave}
        className="w-256 p-8 flex-col justify-start items-start gap-8 inline-flex"
      >
        <Field
          label={'Report type'}
          render={({ field }) => (
            <RadioGroup {...field}>
              <div className="self-stretch justify-start items-start gap-3 inline-flex">
                <RadioButton cards value={reportType.PERFORMANCE.value}>
                  <div className="justify-center items-center gap-1.5 inline-flex">
                    <div className="card-header">Performance report</div>
                    <Check className="text-primary-electric-500 w-5 h-5" />
                  </div>
                  <div className="card-text">
                    A breakdown of selected dimensions so that you can effectively monitor which
                    aspects of your campaign are performing.
                  </div>
                </RadioButton>
                <RadioButton cards value={'DATAPATH'} disabled>
                  <div className="justify-center items-center gap-1.5 inline-flex">
                    <div className=" card-header">Data path report</div>
                  </div>
                  <div className=" card-text">
                    Visualize what path each of your users took towards a conversion.
                  </div>
                </RadioButton>
              </div>
            </RadioGroup>
          )}
          control={control}
          name={'reportType'}
          error={errors.reportType?.message}
        />
        <div className="w-96 flex-col justify-start items-start gap-8 inline-flex">
          <Field
            label={'Name of report'}
            render={({ field, fieldState: { invalid } }) => (
              <Input
                {...field}
                invalid={invalid}
                placeholder="Enter report name..."
                maxLength={NAME_MAX_LENGTH}
              />
            )}
            control={control}
            name={'name'}
            error={errors.name?.message}
            secondaryLabel={
              <div className="text-base-xs leading-tight font-medium flex items-center text-primary-gray-500">
                {`${watch('name')?.length}/${NAME_MAX_LENGTH}`}
              </div>
            }
          />

          <Field
            label={'Date range'}
            render={({ field }) => {
              const { value, ...rest } = field;

              return (
                <DateRangePicker
                  className="w-full"
                  value={[value.startDate, value.endDate]}
                  timeSlice={!isCustomDateRange ? watch('dateRange')?.period : ''}
                  {...rest}
                />
              );
            }}
            control={control}
            name={'dateRange'}
            error={errors.dateRange?.message}
          />

          <Field
            label={'Attribution settings'}
            render={({ field }) => (
              <RadioGroup {...field} className="w-full">
                <div className="self-stretch flex">
                  {Object.values(attributionSettings).map(({ value, label }, i) => (
                    <RadioButton key={`attributionSettings-radio-${i}`} value={value}>
                      <div className="justify-center items-center gap-1.5 inline-flex">
                        {label}
                        <Check className="text-primary-electric-500 w-5 h-5 check-icon" />
                      </div>
                    </RadioButton>
                  ))}
                </div>
              </RadioGroup>
            )}
            control={control}
            name={'showUniques'}
            error={errors.showUniques?.message}
          />

          <Field
            label={'Methodology'}
            render={({ field }) => (
              <Select className="w-full" {...field}>
                {Object.values(methodologyOptions).map(({ value, label }, i) => (
                  <Option key={`methodology-dropdown-${i}`} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            )}
            control={control}
            name={'methodology'}
            error={errors.methodology?.message}
          />

          <Field
            label={'Conversion window (days)'}
            render={({ field }) => (
              <Select className="w-full" {...field}>
                {Object.values(conversionWindow).map((value, i) => (
                  <Option key={`methodology-dropdown-${i}`} value={value}>
                    {value}
                  </Option>
                ))}
              </Select>
            )}
            control={control}
            name={'conversionWindow'}
            error={errors.conversionWindow?.message}
          />

          <Field
            label={'Interval'}
            render={({ field }) => (
              <Select className="w-full" {...field}>
                {Object.values(intervals).map(({ value, label }, i) => (
                  <Option key={`intervals-dropdown-${i}`} value={value}>
                    {label}
                  </Option>
                ))}
              </Select>
            )}
            control={control}
            name={'interval'}
            error={errors.interval?.message}
          />
        </div>
        <div className="w-full flex-col justify-start items-start gap-8 inline-flex">
          <Field
            label={'Report builder'}
            render={({ field }) => (
              <GlobalFilter
                dimensions={field.value || []}
                onChange={field.onChange}
                showDimensions={false}
                maxFilterCount={1}
              />
            )}
            control={control}
            name={'dimensionFilters'}
            error={errors.dimensionFilters?.message}
          />

          <Field
            render={({ field }) => (
              <div className="self-stretch h-112 w-full bg-white rounded-lg border border-primary-gray-100 flex-col justify-start items-start flex overflow-hidden">
                <div className="self-stretch h-16 px-6 border-b border-primary-gray-100 justify-start items-center inline-flex ">
                  <div className="justify-start items-center gap-1.5 flex">
                    <div className="w-8 h-8 flex-col justify-center items-center inline-flex">
                      <div className="rounded border border-primary-electric-500 justify-start items-start inline-flex overflow-hidden">
                        <div className="w-2.5 h-6 bg-primary-electric-100 border-r border-primary-electric-500" />
                        <div className="w-5 h-6" />
                      </div>
                    </div>
                    <div className="text-secondary-black text-base-sm font-semibold leading-snug">
                      Select dimensions
                    </div>
                  </div>
                </div>
                <div className="self-stretch grow shrink basis-0 justify-start items-start inline-flex overflow-hidden">
                  <div className="px-6 py-2 h-full w-1/2 overflow-y-scroll bg-primary-gray-25 border-r border-primary-gray-100">
                    <CheckboxGroup
                      {...field}
                      options={[
                        { label: 'Overall', value: OVERALL },
                        ...(Object.values(dimensions).map(({ value, label: { shownName } }) => ({
                          label: shownName,
                          value,
                          disabled: hasOverall(field.value),
                        })) || []),
                      ]}
                      column
                      className="gap-2"
                    />
                  </div>
                  <div className="px-6 py-4 w-1/2 overflow-y-scroll h-full">
                    <div className="text-secondary-black text-base-md font-medium  leading-snug mb-2">
                      Active dimensions
                    </div>
                    <div className="w-full">
                      <SortableList
                        items={skipIfOverall(field.value).map((value) => dimensions[value])}
                        setItems={field.onChange}
                        renderItem={(item) => (
                          <div className="h-10 w-full px-2 bg-primary-gray-50 rounded-lg justify-between items-center inline-flex">
                            <div className="justify-start items-center gap-1.5 flex">
                              <DragHandle />
                              <div className="text-secondary-black text-base-sm font-normal ">
                                {item.label?.shownName}
                              </div>
                            </div>
                            <X
                              className="w-5 h-5 text-secondary-black cursor-pointer hover:text-primary-gray-500"
                              onClick={() => {
                                field.onChange(field.value.filter((d: string) => d !== item.value));
                              }}
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            control={control}
            name={'dimensions'}
            error={errors.dimensions?.message}
          />

          <Field
            render={({ field }) => (
              <div className="self-stretch h-112 w-full bg-white rounded-lg border border-primary-gray-100 flex-col justify-start items-start flex overflow-hidden">
                <div className="self-stretch h-16 px-6 border-b border-primary-gray-100 justify-start items-center inline-flex ">
                  <div className="justify-start items-center gap-1.5 flex">
                    <div className="w-8 h-8 flex-col justify-center items-center inline-flex">
                      <div className="rounded border border-primary-electric-500 justify-start items-start inline-flex overflow-hidden">
                        <div className="w-2.5 h-6 border-r border-primary-electric-500" />
                        <div className="w-5 h-6 bg-primary-electric-100" />
                      </div>
                    </div>
                    <div className="text-secondary-black text-base-sm font-semibold leading-snug">
                      Select metrics
                    </div>
                  </div>
                </div>
                <div className="self-stretch grow shrink basis-0 justify-start items-start inline-flex overflow-hidden">
                  <div className="px-6 py-2 h-full w-1/2 overflow-y-scroll bg-primary-gray-25 border-r border-primary-gray-100">
                    <CheckboxGroup
                      {...field}
                      options={
                        Object.values(metrics).map(({ value, label: { shownName } }) => ({
                          label: shownName,
                          value,
                        })) || []
                      }
                      column
                      className="gap-2"
                      handleSelectAll={field.onChange}
                      selectAllLabel="All metrics"
                    />
                  </div>
                  <div className="px-6 py-4 w-1/2 overflow-y-scroll h-full">
                    <div className="text-secondary-black text-base-md font-medium  leading-snug mb-2">
                      Active metrics
                    </div>
                    <div className="w-full">
                      <SortableList
                        items={skipIfOverall(field.value).map((value) => metrics[value])}
                        setItems={field.onChange}
                        renderItem={(item) => (
                          <div className="h-10 w-full px-2 bg-primary-gray-50 rounded-lg justify-between items-center inline-flex">
                            <div className="justify-start items-center gap-1.5 flex">
                              <DragHandle />
                              <div className="text-secondary-black text-base-sm font-normal ">
                                {item.label?.shownName}
                              </div>
                            </div>
                            <X
                              className="w-5 h-5 text-secondary-black cursor-pointer hover:text-primary-gray-500"
                              onClick={() => {
                                field.onChange(field.value.filter((d: string) => d !== item.value));
                              }}
                            />
                          </div>
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            control={control}
            name={'metrics'}
            error={errors.metrics?.message}
          />
        </div>
        <div className="w-96 flex-col justify-start items-start gap-8 inline-flex">
          <Field
            label={'Format'}
            render={({ field }) => (
              <RadioGroup {...field} className="w-full">
                <div className="self-stretch flex">
                  {Object.values(formats)
                    .reverse()
                    .map(({ value, label }, i) => (
                      <RadioButton key={`formats-radio-${i}`} value={value}>
                        <div className="justify-center items-center gap-1.5 inline-flex">
                          {label}
                          <Check className="text-primary-electric-500 w-5 h-5 check-icon" />
                        </div>
                      </RadioButton>
                    ))}
                </div>
              </RadioGroup>
            )}
            control={control}
            name={'reportFormat'}
            error={errors.reportFormat?.message}
          />

          <Field
            disabled={isCustomDateRange}
            render={({ field }) => (
              <Checkbox {...field} checked={isCustomDateRange ? false : field.value}>
                <span className="text-base-sm font-medium leading-tight">
                  Scheduled email report
                </span>
              </Checkbox>
            )}
            control={control}
            name={'isScheduled'}
            error={errors.isScheduled?.message}
          />

          {isScheduleEnabled ? (
            <EmailInput
              setEmails={setEmails}
              label="Recipient email"
              placeholder="Enter..."
              emails={watch('emails')}
              fieldError={errors.emails?.message}
            />
          ) : null}
        </div>
        <div className="w-168 flex-col justify-start items-start gap-8 inline-flex">
          {isScheduleEnabled ? (
            <Field
              label={'Cadence'}
              render={({ field }) => (
                <RadioGroup {...field} className="w-full">
                  <div className="self-stretch flex">
                    {Object.values(cadence).map(({ value, label }, i) => (
                      <RadioButton key={`cadence-radio-${i}`} value={value}>
                        <div className="justify-center items-center gap-1.5 inline-flex">
                          {label}
                          <Check className="text-primary-electric-500 w-5 h-5 check-icon" />
                        </div>
                      </RadioButton>
                    ))}
                  </div>
                </RadioGroup>
              )}
              control={control}
              name={'cadenceType'}
              error={errors.cadenceType?.message}
            />
          ) : null}

          {isScheduleEnabled ? (
            <div className="h-10 w-full justify-start items-start gap-2 inline-flex">
              {watch('cadenceType') === 'WEEKLY' ? (
                <Field
                  render={({ field }) => (
                    <Select className="w-full" {...field}>
                      {Object.values(weeklyCadence).map(({ value, label }, i) => (
                        <Option key={`weeks-dropdown-${i}`} value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  )}
                  control={control}
                  name={'weeklyCadence'}
                  error={errors.weeklyCadence?.message}
                />
              ) : null}

              {watch('cadenceType') === 'MONTHLY' ? (
                <Field
                  render={({ field }) => (
                    <Select className="w-full" {...field}>
                      {Object.values(monthlyCadence).map(({ value, label }, i) => (
                        <Option key={`months-dropdown-${i}`} value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  )}
                  control={control}
                  name={'monthlyCadence'}
                  error={errors.monthlyCadence?.message}
                />
              ) : null}

              <Field
                render={({ field }) => (
                  <Select className="w-full max-w-88" {...field}>
                    {Object.values(dailyCadence).map(({ value, label }, i) => (
                      <Option key={`hours-dropdown-${i}`} value={value}>
                        {label}
                      </Option>
                    ))}
                  </Select>
                )}
                control={control}
                name={'dailyCadence'}
                error={errors.dailyCadence?.message}
              />
            </div>
          ) : null}

          {isCustomDateRange ? (
            <Indicator variant="warning" size="lg">
              This is not available when selecting a custom date range. Please select a
              pre-determined date range in order to schedule email reports.
            </Indicator>
          ) : null}
        </div>
      </form>
    </div>
  );
};
