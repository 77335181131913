/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { object, string } from 'yup';

import { Button, Checkbox, Field, Input } from 'components/common';

import { View } from 'modules/performance/performanceApiTypes';
import { usePerformance } from 'modules/performance/usePerformance';

type CurrentViewModalProps = {
  onClose: () => void;
  view?: undefined | View;
};

interface FormInput {
  name: string;
}

const schema = object()
  .shape({
    name: string().required('View Name is required'),
  })
  .required();

export const MAX_LENGTH = 30;

export const CurrentViewModal = ({ onClose, view }: CurrentViewModalProps) => {
  const [selectCheckbox, setSelectCheckbox] = useState<boolean>(view?.isDefault || false);
  const { saveView, updateView } = usePerformance();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    watch,
  } = useForm<FormInput>({
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: {
      name: view?.name || 'Custom',
    },
  });

  const handleViewFormSubmit = handleSubmit((data) => {
    if (view?.viewId !== '0') {
      updateView(data.name, selectCheckbox, view?.viewId as string);
    } else {
      saveView(data.name, selectCheckbox);
    }
    onClose();
  });

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-10 text-secondary-black">
            Save current view
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          <form
            onSubmit={handleViewFormSubmit}
            className="w-full gap-4 flex flex-col justify-start items-start"
          >
            <div className="flex-col  gap-2 flex w-full">
              <Field
                label={'View name'}
                render={({ field, fieldState: { invalid } }) => (
                  <Input {...field} maxLength={MAX_LENGTH} invalid={invalid} />
                )}
                name="name"
                control={control}
                error={errors.name?.message?.toString()}
                secondaryLabel={
                  <div className="text-base-xs leading-tight font-medium flex items-center text-primary-gray-500">
                    {`${watch('name').length}/${MAX_LENGTH}`}
                  </div>
                }
              />
            </div>
          </form>
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Checkbox
            className="mr-12 text-base-xs font-medium leading-tight"
            checked={selectCheckbox}
            onChange={(e) => setSelectCheckbox(e.target.checked)}
          >
            Set as default view
          </Checkbox>
          <Button size="md" variant="secondary" onClick={onClose} className="w-20">
            Cancel
          </Button>
          <Button size="md" variant="primary" disabled={!isValid} onClick={handleViewFormSubmit}>
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
