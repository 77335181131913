import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useCountdown } from 'usehooks-ts';
import { object, string } from 'yup';

import { Button, Field, Wordmark, VideoBackground, Indicator } from 'components/common';
import { Input } from 'components/common/input/input';
import { Paragraph } from 'components/common/typography';

import { ForgotPasswordPayload } from 'modules/auth/types';
import { useAuth } from 'modules/auth/useAuth';

import { ROUTES } from 'routing/constants';

export const ForgotPasswordPage = () => {
  const { forgotPassword, errorText } = useAuth();
  const [currentStep, setCurrentStep] = useState(1);
  const [count, { startCountdown, resetCountdown }] = useCountdown({
    countStart: 30,
  });

  const schema = object()
    .shape({
      username: string().email('Please enter a valid email address.').required('Email is required'),
    })
    .required();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ForgotPasswordPayload>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const handleFormSubmit = handleSubmit(forgotPassword);

  const onSend = () => {
    handleFormSubmit();
    startCountdown();
    setCurrentStep(2);
  };

  const onResend = () => {
    resetCountdown();
    handleFormSubmit();
    startCountdown();
  };

  const firstStep = (
    <form onSubmit={onSend} className="w-full">
      <div className="stretch-to-form gap-10">
        <div className="stretch-to-form gap-3">
          <h1 className="text-heading-4xl font-bold leading-10 text-secondary-black -tracking-[0.022em]">
            Forgot your password?
          </h1>
          <Paragraph className="text-primary-gray-500">
            Don’t worry, it happens to everyone. Just enter the email address you used when you
            joined and we’ll send you instructions to reset your password.
          </Paragraph>
        </div>
        <div className="stretch-to-form gap-3">
          <Field
            label={'Email'}
            render={({ field, fieldState: { invalid } }) => <Input {...field} invalid={invalid} />}
            name="username"
            control={control}
            error={errors.username?.message}
          />
        </div>
        <div className="stretch-to-form gap-1">
          <Button
            size="lg"
            variant="primary"
            isLoading={false}
            disabled={!isValid}
            className="w-full"
            onClick={onSend}
          >
            Send reset email
          </Button>
          <Link to={ROUTES.LOGIN} className="w-full">
            <Button size="lg" variant="tertiary" className="w-full">
              Back to login
            </Button>
          </Link>
        </div>
      </div>
    </form>
  );

  const secondStep = (
    <form onSubmit={onResend} className="w-full">
      <div className="stretch-to-form gap-10">
        <div className="stretch-to-form gap-3">
          <h1 className="self-stretch text-heading-4xl font-bold leading-10 text-secondary-black -tracking-[0.022em]">
            Please check your inbox.
          </h1>
          <Paragraph className="text-primary-gray-500">
            We just sent you an email with instructions on how to reset your password. If you don’t
            see anything in your inbox, please check your spam folder.
          </Paragraph>
        </div>
        {errorText && (
          <Indicator variant="danger" size="lg">
            {errorText}
          </Indicator>
        )}
        <div className="stretch-to-form gap-1">
          <Button
            size="lg"
            variant="secondary"
            isLoading={false}
            disabled={!!count}
            className="w-full"
            onClick={onResend}
          >
            {count ? `Resend email in ${count}` : 'Resend email'}
          </Button>
          <Link to={ROUTES.LOGIN} className="w-full">
            <Button size="lg" variant="tertiary" className="w-full">
              Back to login
            </Button>
          </Link>
        </div>
      </div>
    </form>
  );

  return (
    <VideoBackground>
      <div className="dialog w-128 p-10 bg-white rounded-xl shadow flex-col justify-start items-center inline-flex">
        <div className="self-stretch flex-col justify-start items-start gap-12 flex">
          <Wordmark variant="gradient-dark" className="w-40 h-7" />
          {currentStep === 1 && firstStep}
          {currentStep === 2 && secondStep}
        </div>
      </div>
    </VideoBackground>
  );
};
