import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Field, Indicator, Input } from 'components/common';

import { useXandr } from 'modules/xandr/useXandr';

import {
  XandrConnectionFormInputs,
  XandrConnectionModalTypes,
  schema,
} from './XandrConnectionTypes';

export const XandrConnectionModal = ({ onClose }: XandrConnectionModalTypes) => {
  const { getXandrConnection, isLoading, errorText, status } = useXandr();
  const [error, setError] = useState('');
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<XandrConnectionFormInputs>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    status && onClose();
    if (!status && errorText) setError('Xandr Advertiser ID doesn’t exist');
  }, [status, onClose, errorText]);

  useEffect(() => {
    setError('');
  }, []);

  const handleFormSubmit = handleSubmit(({ id }) => {
    getXandrConnection(id);
  });

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-10 text-secondary-black">
            Connect Xandr advertiser
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          <form
            onSubmit={handleFormSubmit}
            className="w-full gap-4 flex flex-col justify-start items-start"
          >
            <Field
              label={'Xandr Advertiser ID'}
              render={({ field, fieldState: { invalid } }) => (
                <Input {...field} invalid={invalid} placeholder="Enter Xandr Advertiser ID..." />
              )}
              control={control}
              name={'id'}
              error={errors.id?.message}
            />
          </form>

          <div className="stretch-to-form gap-3">
            {error && (
              <Indicator variant="danger" size="lg">
                {error}
              </Indicator>
            )}
          </div>
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button
            size="md"
            variant="secondary"
            onClick={onClose}
            className="w-20"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            size="md"
            variant="primary"
            disabled={!isValid}
            onClick={handleFormSubmit}
            isLoading={isLoading}
          >
            Connect
          </Button>
        </div>
      </div>
    </div>
  );
};
