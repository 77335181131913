import { Flex } from 'antd';
import { Check } from 'feather-icons-react';
import { useState } from 'react';
import { expandConstants } from 'utils';

import { Button, RadioButton, RadioGroup } from 'components/common';
import { Select } from 'components/common/select';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import { AdvancedConfiguration } from 'modules/performance/performanceApiTypes';

const convertToOptions = (items: (string | number)[]) =>
  items.map((item) => ({
    label: item,
    value: item,
  }));

type AdvancedConfigurationModalProps = {
  advancedConfiguration: AdvancedConfiguration;
  onClose: () => void;
  onSubmit: (advancedConfiguration: AdvancedConfiguration) => void;
};

export const AdvancedConfigurationModal = ({
  advancedConfiguration,
  onClose,
  onSubmit,
}: AdvancedConfigurationModalProps) => {
  const { methodology, conversionWindow, showUniques } = advancedConfiguration;
  const { data: constants } = useConstantsQuery(null);
  const attributionSettings = (constants && constants.AttributionSettings) || {};
  const methodologyOptions = expandConstants((constants && constants.MethodologyOptions) || {});
  const conversionWindowOptions = convertToOptions((constants && constants.ConversionWindow) || []);

  const [selectedMethodology, setSelectedMethodology] = useState(methodology);
  const [selectedAttribute, setSelectedAttribute] = useState(showUniques);
  const [selectedConversionWindow, setSelectedConversionWindow] = useState(conversionWindow);

  const handleAdvancedConfiguration = () => {
    onSubmit({
      methodology: selectedMethodology,
      showUniques: selectedAttribute,
      conversionWindow: selectedConversionWindow,
    });
    onClose();
  };

  const updateOptionWithIcon = (options: { label: string | number; value: boolean }[]) =>
    options.map(({ value, label }, i) => (
      <RadioButton key={`options-${i}`} value={value}>
        <div className="justify-center items-center gap-1.5 inline-flex">
          {label}
          <Check className="text-primary-electric-500 w-5 h-5 check-icon" />
        </div>
      </RadioButton>
    ));

  return (
    <div className="w-[512px] bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-10 text-secondary-black">
            Advanced configuration
          </h1>
        </div>
      </div>
      <div className="w-full px-6 pt-3 pb-6">
        <div className="pb-1.5 text-base-xs font-medium leading-none">Attribution settings</div>
        <RadioGroup
          buttonStyle="solid"
          className="flex pb-6"
          value={selectedAttribute}
          onChange={(option) => setSelectedAttribute(option.target.value)}
        >
          {updateOptionWithIcon(Object.values(attributionSettings))}
        </RadioGroup>
        <Flex gap="small" className="h-[62px] gap-1.5 inline-flex">
          <div>
            <div className="pb-1.5 self-stretch text-base-xs font-medium leading-none">
              Methodology
            </div>
            <Select
              placeholder="Select..."
              className="w-56"
              value={selectedMethodology}
              options={Object.values(methodologyOptions)}
              onChange={(option) => setSelectedMethodology(option)}
            />
          </div>
          <div>
            <div className="pb-1.5 self-stretch text-base-xs font-medium leading-none">
              Conversion window (days)
            </div>
            <Select
              placeholder="Select..."
              className="w-56"
              value={selectedConversionWindow}
              options={conversionWindowOptions}
              onChange={(option) => setSelectedConversionWindow(option)}
            />
          </div>
        </Flex>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={onClose} className="w-20">
            Cancel
          </Button>
          <Button size="md" variant="primary" onClick={handleAdvancedConfiguration}>
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
