import { LoadingOutlined } from '@ant-design/icons';
import { Empty, Flex } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import dayjs from 'dayjs';
import { EChartOption } from 'echarts';
import { useEffect, useMemo, useState } from 'react';
import {
  findKeyByValue,
  formatNumber,
  isWithinSameMonth,
  isWithinSameWeek,
  isWithinSameYear,
} from 'utils';

import { Chart, ChartOption, Checkbox, InfoTooltip, Segmented } from 'components/common';
import { METRIC_COLORS, MetricKeys } from 'components/constants';

import { useConstantsQuery } from 'modules/apiData/dataApiSlice';
import { usePerformance } from 'modules/performance/usePerformance';

interface PerformanceChartProps {
  selectedMetrics: string[];
}

export const PerformanceChart = ({ selectedMetrics }: PerformanceChartProps) => {
  const {
    charts,
    isChartLoading,
    pickCadence,
    selectedCadence,
    pickCumulative,
    isCumulative,
    range,
    dateRange,
  } = usePerformance();
  const [chartOption, setChartOption] = useState<ChartOption | null>(null);
  const [hasDataToShow, setHasDataToShow] = useState(false);

  const { data: constants } = useConstantsQuery(null);
  const cadence = useMemo(() => (constants && constants.Cadence) || {}, [constants]);
  const metricsDisplay = useMemo(() => (constants && constants.Metrics) || {}, [constants]);

  const cadenceOptions = [
    { label: 'Daily', value: 'Daily', disabled: true },
    { label: 'Weekly', value: 'Weekly', disabled: true },
    { label: 'Monthly', value: 'Monthly', disabled: true },
    { label: 'Yearly', value: 'Yearly', disabled: true },
  ];

  const enableCadenceOptions = (enableOptions: string[]) => {
    return cadenceOptions.map((option) => {
      if (enableOptions.includes(option.value)) {
        return { ...option, disabled: false };
      }

      return option;
    });
  };

  const dailyEnabled = enableCadenceOptions(['Daily']);
  const dailyWeeklyEnabled = enableCadenceOptions(['Daily', 'Weekly']);
  const dailyWeeklyMonthlyEnabled = enableCadenceOptions(['Daily', 'Weekly', 'Monthly']);
  const allEnabled = enableCadenceOptions(['Daily', 'Weekly', 'Monthly', 'Yearly']);

  useEffect(() => {
    if (isChartLoading) {
      setHasDataToShow(false);
    }
    if (!isChartLoading && charts && selectedMetrics.length) {
      const _chartOption: ChartOption = {
        xAxis: {
          data: [],
        },
        series: [],
      };
      const metricsData: Record<string, { data: number[]; color: string }> = {};

      (charts as unknown as Record<string, number>[])?.forEach((chart) => {
        selectedMetrics.forEach((metric: string) => {
          if (!metricsData[metric]) {
            metricsData[metric] = {
              data: [],
              color: 'black',
            };
          }
          metricsData[metric].data.push(chart[metric] || 0);
          if (chart[metric]) {
            setHasDataToShow(true);
            metricsData[metric].color = '';
          }
        });
        (_chartOption.xAxis as EChartOption.XAxis).data?.push(dayjs(chart.DATE).format('MMM D'));
      });

      selectedMetrics.forEach((metric) => {
        _chartOption.series.push({
          name: metricsDisplay[metric]?.shownName || metric,
          data: metricsData[metric]?.data,
          color: metricsData[metric]?.color || METRIC_COLORS[metric as MetricKeys],
          tooltip: {
            valueFormatter(value) {
              if (metricsDisplay[metric]?.unit === '$') {
                return `$${formatNumber(value as number)}`;
              }
              if (metricsDisplay[metric]?.unit === '%') {
                return `${value}%`;
              }
              if (typeof value === 'number') {
                return formatNumber(value);
              }

              return value as string;
            },
          },
        });
      });
      setChartOption(_chartOption);
    }
  }, [isChartLoading, charts, selectedMetrics, metricsDisplay]);

  const handleCadenceChange = (value?: string | null) => {
    if (value) {
      const key = findKeyByValue(cadence, value);

      pickCadence(key);
    }
  };

  const handleCumulativeChange = (e: CheckboxChangeEvent) => {
    pickCumulative(e.target.checked);
  };

  const getCadenceOptions = () => {
    switch (range) {
      case 'CUSTOM':
        if (isWithinSameWeek(dateRange)) return dailyEnabled;
        if (isWithinSameMonth(dateRange)) return dailyWeeklyEnabled;
        if (isWithinSameYear(dateRange)) return dailyWeeklyMonthlyEnabled;

        return allEnabled;
      case 'LAST_7_DAYS':
        return isWithinSameWeek(dateRange) ? dailyEnabled : dailyWeeklyEnabled;
      case 'LAST_30_DAYS':
        return isWithinSameMonth(dateRange) ? dailyWeeklyEnabled : dailyWeeklyMonthlyEnabled;
      case 'LAST_90_DAYS':
        return dailyWeeklyMonthlyEnabled;
      case 'LAST_365_DAYS':
        return isWithinSameYear(dateRange) ? dailyWeeklyMonthlyEnabled : allEnabled;
      case 'LAST_MONTH':
        return dailyWeeklyEnabled;
      case 'LAST_QUARTER':
        return dailyWeeklyMonthlyEnabled;
      case 'LAST_WEEK':
        return dailyEnabled;
      case 'LAST_YEAR':
        return dailyWeeklyMonthlyEnabled;
      case 'MONTH_TO_DATE':
        return isWithinSameMonth(dateRange) ? dailyWeeklyEnabled : dailyWeeklyMonthlyEnabled;
      case 'TODAY':
        return dailyEnabled;
      case 'WEEK_TO_DATE':
        return isWithinSameWeek(dateRange) ? dailyEnabled : dailyWeeklyEnabled;
      case 'YEAR_TO_DATE':
        return allEnabled;
      case 'YESTERDAY':
        return dailyEnabled;
      default:
        return allEnabled;
    }
  };

  return (
    <div className="ctv-performance-chart">
      <Flex justify="flex-start" align="center" gap="middle" className="mb-4">
        <Segmented
          size="large"
          options={getCadenceOptions()}
          value={cadence[selectedCadence]}
          onChange={handleCadenceChange}
        />
        <div className="flex items-center gap-1">
          <Checkbox checked={isCumulative} onChange={handleCumulativeChange}>
            Cumulative
          </Checkbox>
          <InfoTooltip
            title={
              <div className="text-base-sm font-normal">
                The cumulative format is not compatible with the following metrics:
                <br />
                <br />
                <ul className="list-disc list-inside">
                  <li className="text-base-sm font-normal">Reach</li>
                  <li className="text-base-sm font-normal">Frequency</li>
                </ul>
              </div>
            }
          />
        </div>
      </Flex>
      {(hasDataToShow && chartOption && <Chart option={chartOption} />) ||
        (selectedMetrics.length && isChartLoading && (
          <Flex style={{ height: 400 }} align="center" justify="center">
            <LoadingOutlined spin />
          </Flex>
        )) || (
          <Flex style={{ height: 400 }} align="center" justify="center">
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data to show" />
          </Flex>
        )}
    </div>
  );
};
