import { Image } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { Upload } from 'feather-icons-react';
import { ReactNode, useCallback, useState } from 'react';
import { string } from 'yup';

import {
  Button,
  Modal,
  Input,
  Switch,
  InfoTooltip,
  Indicator,
  LinkButton,
} from 'components/common';
import { UploadButton, UploadFile } from 'components/common/UploadButton';
import { FormField } from 'components/common/formField';
import { Select, Option } from 'components/common/select';

import { useAuth } from 'modules/auth/useAuth';
import { defaultAgencyState, UpdateAgencyRequest } from 'modules/organization/organizationApiTypes';
import { useOrganization } from 'modules/organization/useOrganization';
import { UserRoles } from 'modules/user/userApiTypes';

import { JPEG_FILE_MIME_TYPE, PNG_FILE_MIME_TYPE } from 'utils/constants';

import { CreateAgencyModal } from './CreateAgencyModal';
import { mockTimezones } from './mock';

const nameSchema = string().required('Agency name is required');
const phoneSchema = string().required('Phone is required');

export const AgencySettings = () => {
  const { role } = useAuth();
  const { agencies, currentAgencyId, updateAgency, uploadLogo, uploadWordmark } = useOrganization();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const agency = agencies.find((el) => el.id === currentAgencyId) || defaultAgencyState;

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const isAdvancedVisible =
    role === UserRoles.SUPER_ADMIN.value || role === UserRoles.INTERNAL_ADMIN.value;

  const updAgency = useCallback(
    (data: Partial<UpdateAgencyRequest>) => {
      updateAgency({
        agencyId: agency.id || '',
        ...data,
      });
    },
    [agency.id, updateAgency],
  );

  const handleLogoUpload = useCallback(
    async ({ file }: UploadChangeParam<UploadFile>) => {
      const logoUrl = await uploadLogo(file);

      updAgency({ logoUrl });
    },
    [uploadLogo, updAgency],
  );

  const handleWordmarkUpload = useCallback(
    async ({ file }: UploadChangeParam<UploadFile>) => {
      const wordMark = await uploadWordmark(file);

      updAgency({ wordMark });
    },
    [uploadWordmark, updAgency],
  );

  const areCustomLogosUploaded = agency.logoUrl && agency.wordMarkUrl;

  return (
    <div className="w-full h-full bg-white rounded-xl border border-primary-gray-100 flex-col justify-start items-start flex">
      <div className="p-8 flex-col justify-start items-start inline-flex w-full">
        <div className="flex-col justify-start items-start gap-6 flex w-112">
          <div className="text-secondary-black text-heading-xl font-semibold leading-relaxed">
            Agency
          </div>
          <div className="self-stretch flex-col justify-start items-start gap-10 flex">
            <div className="w-full flex-col justify-start items-start gap-4 inline-flex">
              <SubSectionHeader>Details</SubSectionHeader>
              <FormField
                name="name"
                label="Agency name"
                onSubmit={updAgency}
                render={(readOnly) =>
                  // eslint-disable-next-line react/display-name, react/prop-types
                  ({ field, fieldState: { invalid } }) => (
                    <Input
                      {...field}
                      invalid={!readOnly && invalid}
                      readOnly={readOnly}
                      className="w-full"
                    />
                  )}
                value={agency.name}
                fieldSchema={nameSchema}
              />
              <FormField
                name="timeZone"
                label="Timezone"
                onSubmit={updAgency}
                render={(readOnly) =>
                  // eslint-disable-next-line react/display-name, react/prop-types
                  ({ field }) => (
                    <Select className="w-full" {...field} disabled={readOnly}>
                      {mockTimezones.map(({ label, value }, i) => (
                        <Option key={`timezone-dropdown-${i}`} value={value}>
                          {label}
                        </Option>
                      ))}
                    </Select>
                  )}
                value={
                  mockTimezones.find(({ value }) => value === agency.timeZone)?.label ||
                  agency.timeZone
                }
                fieldSchema={phoneSchema}
              />
            </div>
            <div className="w-full flex-col justify-start items-start gap-3 inline-flex">
              <SubSectionHeader>Customization</SubSectionHeader>
              <div className="self-stretch flex-col justify-start items-start gap-6 flex">
                <div className="flex-col justify-start items-start gap-2 inline-flex">
                  <div className="justify-start items-center gap-1.5 inline-flex">
                    <Switch
                      onChange={(checked) => {
                        updAgency({ enabledCustomLogo: checked });
                      }}
                      disabled={!areCustomLogosUploaded}
                      checked={agency.enabledCustomLogo}
                    >
                      Enable custom logo
                    </Switch>
                    <InfoTooltip
                      title="Customize the dashboard by adding your own personal branding.
                    Once enabled, all of the advertiser accounts will also see your logo and wordmark when they log in."
                    ></InfoTooltip>
                  </div>
                  {/* disable indicator when switch is on */}
                  {!agency.enabledCustomLogo ? (
                    <Indicator size="sm" variant="warning">
                      In order to enable custom logos, first upload a logo and wordmark.
                    </Indicator>
                  ) : null}
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-20 h-20 bg-primary-gray-50 rounded-lg border border-primary-gray-100 justify-center items-center flex">
                    {agency.logoUrl ? (
                      // wrap this image
                      <Image
                        src={agency.logoUrl}
                        width={80}
                        height={80}
                        className="object-contain"
                        preview={false}
                      />
                    ) : (
                      <Upload className="w-6 h-6 relative" />
                    )}
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                    <UploadButton
                      onChange={handleLogoUpload}
                      accept={`${PNG_FILE_MIME_TYPE}, ${JPEG_FILE_MIME_TYPE}`}
                    >
                      <LinkButton className="h-auto py-0">Upload logo</LinkButton>
                    </UploadButton>
                    <div className="self-stretch text-primary-gray-500 text-base-xs leading-none">
                      Recommended specs: PNG with transparent background, Square crop, 80px x 80px
                      minimum
                    </div>
                  </div>
                </div>
                <div className="self-stretch justify-start items-center gap-4 inline-flex">
                  <div className="w-20 h-20 bg-primary-gray-50 rounded-lg border border-primary-gray-100 justify-center items-center flex">
                    {agency.wordMarkUrl ? (
                      <Image
                        src={agency.wordMarkUrl}
                        width={80}
                        height={80}
                        className="object-contain"
                        preview={false}
                      />
                    ) : (
                      <Upload className="w-6 h-6 relative" />
                    )}
                  </div>
                  <div className="grow shrink basis-0 flex-col justify-start items-start gap-2 inline-flex">
                    <UploadButton onChange={handleWordmarkUpload} accept={PNG_FILE_MIME_TYPE}>
                      <LinkButton className="h-auto py-0">Upload wordmark</LinkButton>
                    </UploadButton>
                    <div className="self-stretch text-primary-gray-500 text-base-xs leading-none">
                      Recommended specs: PNG with transparent background, 152px x 40px minimum
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isAdvancedVisible ? (
              <div className="w-full flex-col justify-start items-start gap-3 inline-flex">
                <SubSectionHeader>Advanced</SubSectionHeader>
                <Button variant="primary" size="md" onClick={openModal}>
                  Create new agency
                </Button>
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <Modal
        open={isModalOpen}
        footer={null}
        closable={true}
        onCancel={closeModal}
        destroyOnClose={true}
      >
        <CreateAgencyModal onClose={closeModal} />
      </Modal>
    </div>
  );
};

const SubSectionHeader = ({ children }: { children: ReactNode }) => (
  <div className="self-stretch justify-start items-center gap-2 inline-flex">
    <div className="text-secondary-black text-base-md font-medium leading-snug">{children}</div>
    <div className="grow shrink basis-0 h-px bg-secondary-black" />
  </div>
);
