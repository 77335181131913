import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Button, Field, Indicator, Input } from 'components/common';

import { useXandr } from 'modules/xandr/useXandr';

import {
  XandrEditInsertionOrdersFormInputs,
  EditInsertionOrdersModalProps,
  insertionOrderEditSchema,
} from './XandrConnectionTypes';

export const EditInsertionOrderModal = ({
  onClose,
  selectedInsertionOrder,
}: EditInsertionOrdersModalProps) => {
  const { isLoading, errorText, editInsertionOrder } = useXandr();
  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<XandrEditInsertionOrdersFormInputs>({
    resolver: yupResolver(insertionOrderEditSchema),
    values: {
      id: selectedInsertionOrder?.id,
      name: selectedInsertionOrder?.name || '',
      displayName: selectedInsertionOrder?.displayName || '',
    },
  });

  const handleFormSubmit = handleSubmit(async ({ id, displayName }) => {
    try {
      await editInsertionOrder(id, displayName);
      onClose();
    } catch (error) {
      //setErrorText(error);
    }
  });

  return (
    <div className="w-144 bg-white rounded-xl shadow flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-10 text-secondary-black">
            Edit IO connection
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <form
          onSubmit={handleFormSubmit}
          className="w-full gap-4 flex flex-col justify-start items-start"
        >
          <div className="self-stretch justify-start items-start gap-2 inline-flex">
            <Field
              label={'Xandr IO'}
              render={({ field, fieldState: { invalid } }) => (
                <Input {...field} invalid={invalid} disabled />
              )}
              control={control}
              name={'name'}
              error={errors.name?.message}
            />
            <Field
              label={'IO ID'}
              render={({ field, fieldState: { invalid } }) => (
                <Input {...field} invalid={invalid} disabled />
              )}
              control={control}
              name={'id'}
              error={errors.id?.message}
            />
          </div>
          <div className="self-stretch h-16 flex-col justify-start items-start gap-1.5 flex">
            <Field
              label={'Campaign name'}
              render={({ field, fieldState: { invalid } }) => (
                <Input {...field} invalid={invalid} />
              )}
              control={control}
              name={'displayName'}
              error={errors.displayName?.message}
            />
          </div>
          <div className="stretch-to-form gap-3">
            {errorText && (
              <Indicator variant="danger" size="lg">
                {errorText}
              </Indicator>
            )}
          </div>
        </form>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button
            size="md"
            variant="secondary"
            onClick={onClose}
            className="w-20"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            size="md"
            variant="primary"
            disabled={!isValid || !isDirty}
            onClick={handleFormSubmit}
            isLoading={isLoading}
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
