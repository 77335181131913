import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { Button, Field, Indicator, Input } from 'components/common';

import { useOrganization } from 'modules/organization/useOrganization';

import {
  AdvertiserFormInputs,
  CreateAdvertiserModalProps,
  MAX_LENGTH,
  schema,
} from './advertiserSettingsTypes';

export const CreateAdvertiserModal = ({ onClose }: CreateAdvertiserModalProps) => {
  const { getCurrentAgency, currentAgency, createAdvertiser } = useOrganization();
  const {
    control,
    handleSubmit,
    watch,
    reset,
    formState: { errors, isValid },
  } = useForm<AdvertiserFormInputs>({
    resolver: yupResolver(schema),
    mode: 'onChange',
    values: {
      name: '',
    },
  });

  useEffect(() => {
    !currentAgency && getCurrentAgency();
  }, [getCurrentAgency, currentAgency]);

  const errorText = null; //remove when error is retrieved

  const handleFormSubmit = handleSubmit(({ name }) => {
    createAdvertiser({
      name,
      agencyId: currentAgency?.id || '',
    });
    reset();
    onClose();
  });

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-10 text-secondary-black">
            Create new advertiser
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <div className="self-stretch flex-col justify-start items-start gap-1.5 flex">
          <form
            onSubmit={handleFormSubmit}
            className="w-full gap-4 flex flex-col justify-start items-start"
          >
            <Field
              label={'Advertiser name'}
              render={({ field, fieldState: { invalid } }) => (
                <Input
                  {...field}
                  invalid={invalid}
                  placeholder="Enter advertiser name..."
                  maxLength={MAX_LENGTH}
                />
              )}
              control={control}
              name={'name'}
              error={errors.name?.message}
              secondaryLabel={
                <div className="text-base-xs leading-tight font-medium flex items-center text-primary-gray-500">
                  {`${watch('name').length}/${MAX_LENGTH}`}
                </div>
              }
            />
          </form>

          <div className="stretch-to-form gap-3">
            {errorText && (
              <Indicator variant="danger" size="lg">
                {errorText}
              </Indicator>
            )}
          </div>
        </div>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={onClose} className="w-20">
            Cancel
          </Button>
          <Button size="md" variant="primary" disabled={!isValid} onClick={handleFormSubmit}>
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
