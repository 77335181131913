import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { object, string, ref } from 'yup';

import { Field, PasswordInput, PassWordRulesBlock, Button, Indicator } from 'components/common';
import { passwordRegex } from 'components/constants';

import { PasswordConfirmation } from 'modules/auth/types';
import { useAuth } from 'modules/auth/useAuth';

interface PasswordsType extends PasswordConfirmation {
  currentPassword: string;
  newPassword: string;
}

type ChangePasswordModalProps = {
  onClose: () => void;
};

export const ChangePasswordModal = ({ onClose }: ChangePasswordModalProps) => {
  const { changePassword, isLoading, errorText } = useAuth();

  const schema = object()
    .shape({
      currentPassword: string().required('Current password is required'),
      newPassword: string()
        .min(8, 'Password does not meet the requirements.')
        .matches(passwordRegex, 'Password does not meet the requirements.')
        .required('New password is required'),
      confirmPassword: string()
        .required('Confirm password is required')
        .oneOf([ref('newPassword')], 'Your passwords do not match.'),
    })
    .required();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<PasswordsType>({
    resolver: yupResolver(schema),
    mode: 'onBlur',
  });

  const handleFormSubmit = handleSubmit(async ({ currentPassword: oldPassword, newPassword }) => {
    try {
      await changePassword({
        oldPassword,
        newPassword,
      });
      onClose();
    } catch (error) {
      //setErrorText(error);
    }
  });

  return (
    <div className="w-128 bg-white rounded-xl shadow  flex-col justify-start items-start inline-flex">
      <div className="self-stretch px-6 justify-start items-start gap-6 inline-flex">
        <div className="grow shrink basis-0 py-5 flex-col justify-start items-start gap-2 inline-flex">
          <h1 className="self-stretch text-base-md font-medium leading-10 text-secondary-black">
            Change password
          </h1>
        </div>
      </div>
      <div className="px-6 pt-3 pb-6 gap-6 stretch-to-form">
        <form onSubmit={handleFormSubmit} className="w-full">
          <div className="stretch-to-form gap-3">
            <div className="stretch-to-form gap-6">
              <Field
                label={'Current password'}
                render={({ field, fieldState: { invalid } }) => (
                  <PasswordInput
                    {...field}
                    invalid={invalid}
                    placeholder="Please enter your current password..."
                  />
                )}
                name="currentPassword"
                control={control}
                error={errors.currentPassword?.message}
              />
              <div className="stretch-to-form gap-1.5">
                <Field
                  label={'New password'}
                  render={({ field, fieldState: { invalid } }) => (
                    <PasswordInput
                      {...field}
                      invalid={invalid}
                      placeholder="Please enter your new password..."
                    />
                  )}
                  name="newPassword"
                  control={control}
                  error={errors.newPassword?.message}
                />
                <PassWordRulesBlock password={watch('newPassword')} />
              </div>
              <Field
                label={'Confirm password'}
                render={({ field, fieldState: { invalid } }) => (
                  <PasswordInput
                    {...field}
                    invalid={invalid}
                    placeholder="Please re-enter your new password..."
                  />
                )}
                name="confirmPassword"
                control={control}
                error={errors.confirmPassword?.message}
              />
            </div>
            {errorText && (
              <Indicator variant="danger" size="lg">
                {errorText}
              </Indicator>
            )}
          </div>
        </form>
      </div>
      <div className="self-stretch h-16 px-6 border-t border-primary-gray-100 justify-end items-center gap-2 inline-flex">
        <div className="justify-end items-center gap-2 flex">
          <Button size="md" variant="secondary" onClick={onClose} className="w-20">
            Cancel
          </Button>
          <Button
            size="md"
            variant="primary"
            isLoading={isLoading}
            disabled={!isValid}
            onClick={handleFormSubmit}
          >
            Save changes
          </Button>
        </div>
      </div>
    </div>
  );
};
